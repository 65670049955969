import { CookieValueTypes, getCookie } from 'cookies-next';
import { FormikValues } from 'formik';
import { mapToMfeValue } from './form/subjectHelpers';

const mapValue = (paramName: string, value: string): string => {
  if (paramName === 'trialSessionSubject') {
    return mapToMfeValue(value);
  }

  return value;
};

export const fillUrlValues = (url: string, formikValues: FormikValues) => {
  const urlObject = new URL(url, window.location.origin);
  const { searchParams } = urlObject;
  const entries = searchParams.entries();
  const paramsToDelete = [];
  let param;

  do {
    param = entries.next();

    if (param && param.value) {
      const [paramKey, paramValue] = param.value;

      if (paramValue.startsWith('{{') && paramValue.endsWith('}}')) {
        const formikKey = paramValue.slice(2, -2);

        if (formikValues[formikKey]) {
          searchParams.set(
            paramKey,
            mapValue(formikKey, formikValues[formikKey]),
          );
        } else {
          paramsToDelete.push(paramKey);
        }
      }
    }
  } while (!param.done);

  paramsToDelete.forEach((paramKey) => searchParams.delete(paramKey));

  return urlObject.toString();
};

export const addCurrentUrlParamsToUrl = (url: string) => {
  try {
    const { searchParams } = new URL(window.location.href);

    const urlObject = new URL(url, window.location.origin);
    searchParams.forEach((value, key) =>
      urlObject.searchParams.append(key, value),
    );

    return urlObject.toString();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error, url);
    return url;
  }
};

export const addInternalParamsToUrl = (
  url: string,
  params: {
    alwaysIncludeToken?: boolean;
    formikValues?: FormikValues;
    gtmId: string;
    referring: CookieValueTypes;
    landingPage: CookieValueTypes;
    token?: string;
  },
): string => {
  if (!url) {
    return '';
  }

  try {
    const urlObject = new URL(url, window.location.origin);
    const { searchParams } = urlObject;

    if (params.formikValues) {
      searchParams.set('reachOutVia', params.formikValues.reachedOutVia);
      searchParams.set('experiment', params.formikValues.experiment);
      searchParams.set('lang', params.formikValues.language);
    }

    searchParams.set('gtmId', params.gtmId || 'undefined');
    searchParams.set(
      'referring',
      params.referring ? params.referring.toString() : 'undefined',
    );
    searchParams.set(
      'landingPage',
      params.landingPage ? params.landingPage.toString() : 'undefined',
    );
    searchParams.set('origin', window.location.href);
    searchParams.set('country', document.documentElement.lang);

    if (window.Cookiebot) {
      const { consent } = window.Cookiebot;

      if (consent) {
        searchParams.set('cookiesMarketing', consent.marketing?.toString());
        searchParams.set('cookiesNecessary', consent.necessary?.toString());
        searchParams.set('cookiesPreferences', consent.preferences?.toString());
        searchParams.set('cookiesStatistics', consent.statistics?.toString());
      }
    }

    if (params.token || params.alwaysIncludeToken) {
      searchParams.set('token', params.token || 'undefined');
    }

    searchParams.set(
      'customSessionID',
      getCookie('_custom_sessionID')?.toString() || 'undefined',
    );

    return urlObject.toString();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error, url);
    return url;
  }
};

export const getFullReferringPageUrl = (fallback: string) => {
  const url = getCookie('referring')?.toString() || fallback;

  if (url === '') {
    return '';
  }

  try {
    const urlObject = new URL(url);
    if (urlObject.hostname.toLowerCase().includes('gostudent.org')) {
      return '';
    }
  } catch {
    return `Invalid URL - ${url}`;
  }

  return url;
};

export const getFullLandingPageUrl = (fallback: string) => {
  const url = getCookie('landingPage')?.toString() || fallback;

  if (url === '') {
    return '';
  }

  try {
    const urlObject = new URL(url);

    if (urlObject.hostname.toLowerCase().includes('gostudent.org')) {
      return url;
    }
  } catch (err) {
    return `Invalid URL - ${url}`;
  }

  return '';
};
