import { CookieValueTypes } from 'cookies-next';
import { createContext } from 'react';

export const GtmIdContext = createContext('');

type AttributionPropertiesContextType = {
  referringPageFallback?: CookieValueTypes;
  landingPageFallback?: CookieValueTypes;
};

export const AttributionPropertiesContext =
  createContext<AttributionPropertiesContextType>({});

type SSGDataContextType = {
  alternateUrls?: MpcAlternateUrls;
  formLabels?: Record<string, string>;
  countriesByCode?: Record<string, string>;
};

export const SSGDataContext = createContext<SSGDataContextType>({});
